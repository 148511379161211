import React, { createContext, useEffect, useState } from "react"

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light") // Default to light theme

  useEffect(() => {
    // Check localStorage for saved theme
    const savedTheme = localStorage.getItem("theme") || "light"
    setTheme(savedTheme)

    // Apply the theme class to <body>
    document.body.classList.toggle("theme-dark", savedTheme === "dark")
  }, [])

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark"
    setTheme(newTheme)

    // Save the theme to localStorage
    localStorage.setItem("theme", newTheme)

    // Apply or remove the "theme-dark" class
    document.body.classList.toggle("theme-dark", newTheme === "dark")
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext
